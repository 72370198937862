import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "going-zone-less-with-angulars-reactive-extensions"
    }}>{`Going zone-less with Angular's reactive extensions`}</h1>
    <h5 {...{
      "id": "july-21-2020--aptitude-softwares-office"
    }}>{`July 21, 2020 | `}<a parentName="h5" {...{
        "href": "http://twitter.com/AptitudeSW"
      }}>{`Aptitude Software's`}</a>{` office`}</h5>
    <hr></hr>
    <ul>
      <li parentName="ul">{`📊 `}<strong parentName="li">{`Slides`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://slides.com/kajetansw/rx-angular-zone-less"
        }}>{`https://slides.com/kajetansw/rx-angular-zone-less`}</a></li>
      <li parentName="ul">{`⌚ `}<strong parentName="li">{`Duration`}</strong>{`: ~30 minutes`}</li>
      <li parentName="ul">{`👨‍💻 `}<strong parentName="li">{`Form`}</strong>{`: talk + live coding`}</li>
    </ul>
    <hr></hr>
    <p>{`Angular's change detection relies heavily on the Zone.js. Zone wraps asynchronous tasks happening in our Angular
applications and triggers change detection almost "automagically".`}</p>
    <p>{`Most of the time it's a great reliever for every Angular developer: we don't have to worry about triggering change detection
ourselves - the framework does it for us. Although the Angular team did a great job of optimizing this solution, we subconsciously
know that to every solution there are always some caveats. This is sadly not an exception.`}</p>
    <p>{`This talk presents a few problems we have to face when dealing with Zone.js and change detection and what are the conditions that
lead to those. A solution for those problems is also presented: those that come straight from the core Angular and those provided
by external libraries.`}</p>
    <hr></hr>
    <h3 {...{
      "id": "outline"
    }}>{`Outline`}</h3>
    <ul>
      <li parentName="ul">{`a quick Zone.js and change detection remainder `}</li>
      <li parentName="ul">{`problems with Zone.js and Angular's change detection`}</li>
      <li parentName="ul">{`possible solutions from the core Angular`}</li>
      <li parentName="ul">{`external libraries as solutions`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      